
<div *ngIf="processing">
  <div id="globalLoader" class="global-loader" [ngStyle]="{ 'background-color': sharedService.darkMode ? '#333' : '#fff' }">
    <h1>Loading</h1>
  </div>
</div>

<div *ngIf="!processing">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">
      <span>Online payment failed</span>
    </div>

    <mat-card class="note" *ngIf="isValid">
      <span >Your online payment has <strong>Failed</strong>.  Please remember: <br/><br />      
        <ul>
          <li>Processing of applications and/or application changes will not be processed and/or approved until the payment has cleared. </li>
          <li>You can revisit your <strong><a [routerLink]="['/payments']" >Payments</a></strong> to continue the payment process online or see other payment options. </li>
        </ul>
      </span>
    </mat-card>
  </div>

