
<div *ngIf="processing">
  <div id="globalLoader" class="global-loader" [ngStyle]="{ 'background-color': sharedService.darkMode ? '#333' : '#fff' }">
    <h1>Loading</h1>
  </div>
</div>

<div *ngIf="!processing">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="m-b-16">
      <span>Online payment duplicate detected</span>
    </div>

    <mat-card class="note" *ngIf="isValid">
      <span >The payment processing system has detected a <strong>Duplicate</strong> payment. <br/><br />      
        <ul>
          <li>Please contact the appropriate team via the <strong><a [routerLink]="['/messages']" >Messaging System</a></strong> to look into the issue.  </li>
        </ul>
      </span>
    </mat-card>
  </div>

